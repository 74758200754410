import axios from "axios";
import { BASE_URL } from "App/constants";
import { getAcademyId } from "Utils/utils";

interface PlayerEngagementParams {
  academyId: number;
  playerId?: number;
  startDate: string;
  endDate: string;
}

export const fetchPlayerEngagementScore = async function (
  params: PlayerEngagementParams
) {
  const { academyId, playerId, ...queryParams } = params;

  const { data } = await axios.get(
    `${BASE_URL}academies/${getAcademyId(
      academyId
    )}/players/${playerId}/app_engagement_score`,
    {
      params: {
        start_date: queryParams.startDate,
        end_date: queryParams.endDate,
      },
    }
  );

  return data;
};
