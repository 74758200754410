import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { faPlus } from "@fortawesome/free-solid-svg-icons";

import {
  deleteVideoDetails,
  getAssetList,
  postVideoDetails,
} from "Api/Pages/VideoListApi";
import AssetLandingPage from "Components/Shared/AssetLandingPage";

import { getAssetColumns } from "./constants";

import {
  ASSET_TYPE_ENUM,
  IAssetDetails,
  UPLOAD_PURPOSE_ENUM,
} from "Components/Shared/UploadAsset/types";
import { useEffect, useState } from "react";
import USE_QUERY_KEYS_CONSTANTS from "Api/useQueryKeyConstants";

import { AssetType } from "Components/Shared/AssetLandingPage/types";
import { INITIAL_PAGE } from "App/constants";

const PublicAsset = () => {
  const navigate = useNavigate();
  const [isUploadModalOpen, setUploadModalVisibility] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(INITIAL_PAGE);
  // Upload the file and get the S3 object key

  const { data: publicAssetResponse, refetch: fetchPublicAssets } = useQuery(
    USE_QUERY_KEYS_CONSTANTS.publicAssetList,
    () =>
      getAssetList({
        currentPage: currentPage,
        per_page: 10,
        upload_purpose: UPLOAD_PURPOSE_ENUM.public,
      }),
    { refetchOnWindowFocus: false, enabled: false }
  );

  useEffect(() => {
    fetchPublicAssets();
  }, [fetchPublicAssets, currentPage]);

  const toggleVideoUploadModal = () => {
    setUploadModalVisibility((isOpen) => !isOpen);
  };

  const onDeleteRowClicked = async (selectedId: number) => {
    await deleteVideoDetails(selectedId);

    fetchPublicAssets();
  };
  const onRowClicked = (rowData: AssetType) => {
    navigate(`/public-assets/details/${rowData.id}`, { state: rowData });
  };

  const onVideoSaveCallback = async (videoDetails: IAssetDetails) => {
    const { title, description, kpoint_gcc_id, thumbnail_url, object_key } =
      videoDetails;
    await postVideoDetails({
      thumbnail_url,
      kpoint_gcc_id,
      title,
      description,
      s3_object_key: object_key || "",
      of_type: ASSET_TYPE_ENUM.image,
      upload_purpose: UPLOAD_PURPOSE_ENUM.public,
    });

    fetchPublicAssets();
  };
  return (
    <AssetLandingPage
      uploadModalProps={{
        purpose: UPLOAD_PURPOSE_ENUM.public,
      }}
      title="Promotional Assets"
      actionButtonProps={{
        label: "Upload Asset",
        icon: faPlus,
        onClickHandler: toggleVideoUploadModal,
      }}
      tableProps={{
        columns: getAssetColumns(onDeleteRowClicked),
        tableData: publicAssetResponse?.data || [],
        noDataMessage: "Please upload assets",
        onRowClick: onRowClicked,
        clickable: true,
        paginationProps: {
          totalCount: publicAssetResponse?.total_count || 0,
          currentPage: currentPage,
          numberOfRowPerPage: 10,
          setPageNumber: setCurrentPage,
        },
      }}
      isUploadModalOpen={isUploadModalOpen}
      //sizeConfig={getSizeConfig(assetConfig)}
      toggleVideoUploadModal={toggleVideoUploadModal}
      onVideoSaveCallback={onVideoSaveCallback}
    />
  );
};

export default PublicAsset;
