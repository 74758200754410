import { BASE_URL } from "App/constants";
import axios from "axios";
import { getAcademyId } from "Utils/utils";

interface FetchScheduleParams {
  academy_id?: number;
  of_type: string;
  start_date: string;
  end_date: string;
  entity_type_for_academy_schedules: string;
  page?: number;
  pageSize?: number;
}

export const fetchAcademySchedule = async ({
  academy_id,
  of_type,
  start_date,
  end_date,
  entity_type_for_academy_schedules,
  page = 1,
  pageSize = 10,
}: FetchScheduleParams) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}academies/${getAcademyId(academy_id)}/user_schedules`,
      {
        params: {
          page,
          pageSize,
          academy_id,
          of_type,
          start_date,
          end_date,
          entity_type_for_academy_schedules,
        },
      }
    );

    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMessage =
        error.response?.data?.error || "An unexpected Error has occurred";
      throw new Error(errorMessage);
    }
  }
};
