import { useState, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

import { useAppSelector } from "App/hooks";

import { fetchAcademySchedule } from "Api/Pages/UserSchedulePageApi";
import { PaginationControl, TableComponent } from "Components/Shared";
import { HeaderComponent } from "Components/Shared";

import { columns } from "./constants";

const UserSchedulesPage = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [searchParams] = useSearchParams();
  const ofType = searchParams.get("of_type") || "travel";
  const startDate = searchParams.get("start_date") || "";
  const endDate = searchParams.get("end_date") || "";
  const entity_type_for_academy_schedules =
    searchParams.get("entity_type_for_academy_schedules") || "Player";
  const pageSize = 10;
  const {
    userDetails: { academy_id },
  } = useAppSelector((state) => state.loginReducer);

  const { data, isLoading, error } = useQuery(
    [
      "userSchedules",
      academy_id,
      ofType,
      currentPage,
      startDate,
      endDate,
      entity_type_for_academy_schedules,
    ],
    () =>
      fetchAcademySchedule({
        page: currentPage,
        academy_id: academy_id,
        of_type: ofType,
        start_date: startDate,
        end_date: endDate,
        entity_type_for_academy_schedules: entity_type_for_academy_schedules,
      }),
    {
      enabled: !!startDate && !!endDate,
      keepPreviousData: true,
    }
  );

  useMemo(() => {
    if (data) {
      setTotalPages(Math.ceil(data.total_count / pageSize));
    }
  }, [data]);

  const filteredColumns = useMemo(() => {
    return ofType === "injury"
      ? columns
      : columns.filter((col) => col.dataField !== "rating_points");
  }, [ofType]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error fetching data</div>;

  return (
    <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
      <HeaderComponent
        title={`${entity_type_for_academy_schedules} on ${ofType}`}
        shouldShowNav={false}
        shouldShowBulkUpload={false}
        buttonProps={undefined}
      />

      <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
        <div className="flex-1 scroll-y px-4 mb-4 mt-4">
          <div className="table-responsive">
            {data ? (
              <TableComponent
                columns={filteredColumns}
                tableData={data.data || []}
                hideColWidth={false}
                hideThead={false}
              />
            ) : (
              <div className="text-center text-muted">No Data Found</div>
            )}
          </div>
        </div>

        <div className="pagination-wrap d-flex justify-content-end px-4">
          <PaginationControl
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        </div>
      </div>
    </div>
  );
};

export default UserSchedulesPage;
