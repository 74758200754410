import { Button } from "Components/Core";
import { ButtonIcon } from "Components/Shared";
import VirtualTableComponent from "Components/Shared/VirtualTableComponent";

import UploadAsset from "Components/Shared/UploadAsset";

import {
  IAssetDetails,

  // SizeConfigType,
  UPLOAD_PURPOSE_ENUM,
} from "Components/Shared/UploadAsset/types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { AssetType } from "./types";
import { SportType } from "Pages/Drills/types";
import { AssetTagsData } from "Interfaces/AssetTagsInterface";

interface IProps {
  title: string;
  isUploadModalOpen: boolean;

  // sizeConfig: SizeConfigType;

  sportsList?: Array<SportType>;
  actionButtonProps?: {
    label: string;
    onClickHandler: () => void;
    icon?: IconProp;
  };
  subActionButtonProps?: {
    label: string;
    onClickHandler: () => void;
  };
  tableProps?: {
    clickable: boolean;
    onRowClick: (rowData: AssetType) => void;
    columns: Array<object>;
    tableData: Array<AssetType>;
    noDataMessage: string;
    paginationProps?: {
      totalCount: number;
      currentPage: number;
      numberOfRowPerPage: number;
      setPageNumber: (selectedPageNo: number) => void;
    };
  };
  toggleVideoUploadModal: () => void;
  onVideoSaveCallback: (
    videoDetails: IAssetDetails,
    assetTagsData: AssetTagsData
  ) => void;
  uploadModalProps: {
    purpose: UPLOAD_PURPOSE_ENUM;
  };
}

const AssetLandingPage = (props: IProps) => {
  const {
    title,
    isUploadModalOpen,
    actionButtonProps,
    subActionButtonProps,
    tableProps,
    uploadModalProps,
    sportsList,
    onVideoSaveCallback,
    toggleVideoUploadModal,
  } = props;

  return (
    <>
      <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
        <header className="ss-page-header d-flex align-items-center justify-content-between px-4 ">
          <h2 className="fs-md mb-0 fw-medium">{title}</h2>
          <div className="d-flex align-items-center gap-2">
            {subActionButtonProps && (
              <Button
                variant="outline-secondary"
                onClick={subActionButtonProps.onClickHandler}
              >
                {subActionButtonProps.label}
              </Button>
            )}
            {actionButtonProps && (
              <ButtonIcon
                className="btn-outline-secondary fs-sm"
                onClick={actionButtonProps.onClickHandler}
              >
                {actionButtonProps.icon && (
                  <FontAwesomeIcon
                    icon={actionButtonProps.icon}
                    className="me-2"
                  />
                )}
                {actionButtonProps.label}
              </ButtonIcon>
            )}
          </div>
        </header>
        <hr className="ss-page-seprator mx-4 my-0" />
        <div className="ss-page-body d-flex flex-column flex-1 scroll-y py-4 px-4">
          {tableProps && (
            <VirtualTableComponent
              isClickAble={tableProps.clickable}
              onRowClick={tableProps.onRowClick}
              columns={tableProps.columns}
              tableData={tableProps.tableData}
              noDataMessage={tableProps.noDataMessage}
              paginationProps={tableProps?.paginationProps}
            />
          )}
        </div>
      </div>
      {isUploadModalOpen && (
        <UploadAsset
          isModalVisible
          //sizeConfig={sizeConfig}

          title="Upload Video"
          purpose={uploadModalProps.purpose}
          sportsList={sportsList || []}
          toggleModal={toggleVideoUploadModal}
          onAssetSubmitCallback={onVideoSaveCallback}
        />
      )}
    </>
  );
};

export default AssetLandingPage;
