import FileViewPort from "Components/Shared/DrillsDetailsComponent/FileViewPort";
import HeaderComponent from "Components/Shared/Header";
import {
  ASSET_TYPE_ENUM,
  UPLOAD_PURPOSE_ENUM,
} from "Components/Shared/UploadAsset/types";

import { useLocation, Location } from "react-router-dom";
import { formatDate } from "Utils/helper";
import { IPlayerVideoData } from "../types";

const VideoListDetailsComponent = () => {
  const location: Location = useLocation();

  const {
    title,
    comments,
    thumbnail_url,
    of_type,
    description,
    master_sport_name,
    upload_purpose,
  } = location.state as IPlayerVideoData;

  const getComments = () => {
    if (comments && Array.isArray(comments)) {
      return (
        <div>
          <label className="fs-m">Comments</label>
          {comments.length === 0 && <p className="fs-sm fw-bold mb-0">---</p>}
          <div className="comment mt-4 text-justify float-left">
            <ul>
              {comments.map((comment) => (
                <li key={comment.id}>
                  <b>{comment.commented_by}</b> : <span>{comment.text}</span> -{" "}
                  <span className="text-muted">
                    {formatDate(comment.created_at)}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
      <HeaderComponent title="Asset Details" />
      <div className="ss-page-body d-flex flex-column flex-1 scroll-y">
        <div className="d-flex flex-column flex-1 scroll-y p-4 gap-4">
          {upload_purpose === UPLOAD_PURPOSE_ENUM.public && (
            <FileViewPort
              fileType={
                of_type === ASSET_TYPE_ENUM.image
                  ? `${of_type}/jpeg`
                  : `${of_type}/mp4`
              }
              url={thumbnail_url}
              preview={thumbnail_url}
              videoWrapperClasses="w-50 h-50"
            />
          )}

          <div>
            <label className="fs-xs">Title</label>
            <p className="fs-sm fw-bold mb-0">{title}</p>
          </div>
          <div>
            <label className="fs-xs">Description</label>
            <p className="fs-sm fw-bold mb-0">{description || "---"}</p>
          </div>
          {master_sport_name && (
            <div>
              <label className="fs-xs">Sport</label>
              <p className="fs-sm fw-bold mb-0">{master_sport_name}</p>
            </div>
          )}

          {getComments()}
        </div>
      </div>
    </div>
  );
};

export default VideoListDetailsComponent;
