import { useQuery } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";

import { faPlus } from "@fortawesome/free-solid-svg-icons";

import {
  deleteVideoDetails,
  getAssetList,
  postVideoDetails,
} from "Api/Pages/VideoListApi";
import AssetLandingPage from "Components/Shared/AssetLandingPage";

import { getAssetColumns } from "./constants";

import {
  ASSET_TYPE_ENUM,
  IAssetDetails,
  UPLOAD_PURPOSE_ENUM,
} from "Components/Shared/UploadAsset/types";
import { useEffect, useState } from "react";
import USE_QUERY_KEYS_CONSTANTS from "Api/useQueryKeyConstants";
import { getMasterSportsList } from "Api/Pages/SportsApi";
import { IMasterSportResponse } from "./types";
import { AssetType } from "Components/Shared/AssetLandingPage/types";
import { INITIAL_PAGE } from "App/constants";
import { useCanApproveSubmissionDrillVideos } from "./utils";
import { AssetTagsData } from "Interfaces/AssetTagsInterface";
import { isAllowedTo } from "Utils/helper";
import { useAppSelector } from "App/hooks";

const Drills = () => {
  const navigate = useNavigate();
  let location = useLocation();
  let state = location.state as {
    currentPage: number;
  };
  const {
    userDetails: { permissions, permission_rules },
  } = useAppSelector((state) => state.loginReducer);
  const [isUploadModalOpen, setUploadModalVisibility] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(
    state?.currentPage || INITIAL_PAGE
  );
  const canApproveSubmissionDrillVideos = useCanApproveSubmissionDrillVideos();

  const { data: drillsResponse, refetch: fetchDrillsVideos } = useQuery(
    USE_QUERY_KEYS_CONSTANTS.drillVideosList,
    () =>
      getAssetList({
        currentPage: currentPage,
        per_page: 10,
        upload_purpose: UPLOAD_PURPOSE_ENUM.drill,
        of_type: ASSET_TYPE_ENUM.video,
      }),
    { refetchOnWindowFocus: false, enabled: false }
  );

  const { data: sportsResponse, refetch: fetchMasterSportsList } =
    useQuery<IMasterSportResponse>(
      USE_QUERY_KEYS_CONSTANTS.masterSportsList,
      () => getMasterSportsList(),
      { refetchOnWindowFocus: false, enabled: false }
    );

  useEffect(() => {
    fetchMasterSportsList();
  }, [fetchMasterSportsList]);

  useEffect(() => {
    fetchDrillsVideos();
  }, [fetchDrillsVideos, currentPage]);

  // toggle video details upload modal
  const toggleVideoUploadModal = () => {
    setUploadModalVisibility((isOpen) => !isOpen);
  };

  const onDeleteRowClicked = async (selectedId: number) => {
    await deleteVideoDetails(selectedId);
    // On successful delete fetch drills videos
    fetchDrillsVideos();
  };
  const onRowClicked = (rowData: AssetType) => {
    navigate(`/drills/details/${rowData.id}`, {
      state: { ...rowData, currentPage: currentPage },
    });
  };

  const onVideoSaveCallback = async (
    videoDetails: IAssetDetails,
    assetTagsData: AssetTagsData
  ) => {
    const { title, description, thumbnail_url, kpoint_gcc_id } = videoDetails;
    console.log("thumnail_url", thumbnail_url);

    await postVideoDetails({
      title,
      description,
      thumbnail_url,
      kpoint_gcc_id,
      of_type: ASSET_TYPE_ENUM.video,
      upload_purpose: UPLOAD_PURPOSE_ENUM.drill,
      ...assetTagsData,
    });

    fetchDrillsVideos();
  };

  const handleVideoSubmissionNavigate = () => {
    navigate("/drills/submissions");
  };

  const canManageDestroy = permission_rules?.manage?.Asset;
  const shouldAllowDelete =
    (isAllowedTo("Asset", "destroy", permissions) &&
      permission_rules?.destroy?.Asset?.upload_purpose?.includes("drill")) ||
    canManageDestroy;

  return (
    <AssetLandingPage
      uploadModalProps={{
        purpose: UPLOAD_PURPOSE_ENUM.drill,
      }}
      title="Drills"
      subActionButtonProps={
        canApproveSubmissionDrillVideos
          ? {
              label: "Video Submissions",
              onClickHandler: handleVideoSubmissionNavigate,
            }
          : undefined
      }
      actionButtonProps={{
        label: "Upload Video",
        icon: faPlus,
        onClickHandler: toggleVideoUploadModal,
      }}
      tableProps={{
        columns: getAssetColumns(onDeleteRowClicked, shouldAllowDelete),
        tableData: drillsResponse?.data || [],
        noDataMessage: "Please upload drills",
        onRowClick: onRowClicked,
        clickable: true,
        paginationProps: {
          totalCount: drillsResponse?.total_count || 0,
          currentPage: currentPage,
          numberOfRowPerPage: 10,
          setPageNumber: setCurrentPage,
        },
      }}
      sportsList={sportsResponse || []}
      isUploadModalOpen={isUploadModalOpen}
      //sizeConfig={getSizeConfig(assetConfig)}
      toggleVideoUploadModal={toggleVideoUploadModal}
      onVideoSaveCallback={onVideoSaveCallback}
    />
  );
};

export default Drills;
