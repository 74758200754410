import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useQuery } from "react-query";

import VirtualTableComponent from "../VirtualTableComponent";
import {
  deleteVideoDetails,
  downloadPlayerUploadedPdf,
  getPlayerUploadedAssets,
} from "Api/Pages/VideoListApi";

import { getVideoColumns } from "./constants";
import { IPlayerVideoData } from "./types";
import { useAppSelector } from "App/hooks";
import { getAcademyId } from "Utils/utils";
import ButtonIcon from "../ButtonIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { DOWNLOAD } from "Pages/Reports/constants";
import { download } from "Api/Pages/cloudinaryAssetUpload";
import USE_QUERY_KEYS_CONSTANTS from "Api/useQueryKeyConstants";

import { RECORDS_PER_PAGE } from "App/constants";

interface IProps {
  playerID: string | undefined;
}

const VideoListComponent = (props: IProps) => {
  const { playerID } = props;
  const navigate = useNavigate();
  const {
    userDetails: { academy_id },
  } = useAppSelector((state) => state.loginReducer);
  const [currentPage, setCurrentPage] = useState(1);
  const {
    data: result,
    refetch: fetchPlayerVideos,
    isFetching,
  } = useQuery(
    [USE_QUERY_KEYS_CONSTANTS.playerVideosList, currentPage],
    () =>
      getPlayerUploadedAssets({
        academy_id: getAcademyId(academy_id),
        player_id: playerID,
        page: currentPage,
        per_page: RECORDS_PER_PAGE,
      }),
    { refetchOnWindowFocus: false }
  );

  const onDownloadClick = async () => {
    try {
      const data = await downloadPlayerUploadedPdf({
        academy_id: getAcademyId(academy_id),
        player_id: playerID,
      });
      download(data, `${playerID}-report.pdf`);
    } catch (error) {
      console.error("failed to download error: ", error);
    }
  };
  const onDeleteRowClicked = async (rowId: number) => {
    await deleteVideoDetails(rowId);
    fetchPlayerVideos();
  };
  const onRowClick = (rowData: IPlayerVideoData) => {
    navigate(`/players/${playerID}/asset/${rowData.id}`, { state: rowData });
  };

  useEffect(() => {
    fetchPlayerVideos();
  }, [fetchPlayerVideos, currentPage]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-2 ">
        <ButtonIcon
          className="btn-outline-secondary fs-sm ms-auto"
          onClick={onDownloadClick}
          isdisabled={!result || !result?.data || result?.data.length === 0}
        >
          <FontAwesomeIcon icon={faArrowDown} className="me-2" />
          {DOWNLOAD}
        </ButtonIcon>
      </div>
      {isFetching && (
        <div className="d-flex justify-content-center mt-4">
          <span>Loading...</span>
        </div>
      )}
      <VirtualTableComponent
        isClickAble
        onRowClick={onRowClick}
        columns={getVideoColumns(onDeleteRowClicked)}
        tableData={result?.data || []}
        paginationProps={{
          currentPage: currentPage,
          setPageNumber: (page: number) => {
            setCurrentPage(page);
          },
          totalCount: result?.total_count,
          numberOfRowPerPage: RECORDS_PER_PAGE,
        }}
        noDataMessage="No Videos found"
      />
    </>
  );
};

export default VideoListComponent;
