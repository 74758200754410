import axios from "axios";
import { BASE_URL } from "App/constants";

export const fetchDashboardDetails = async function (params: {
  id: string | undefined;
  user_schedule_start_date: string;
  user_schedule_end_date: string;
  assets_start_date: string;
  assets_end_date: string;
  top_players_start_date: string;
  top_players_end_date: string;
}) {
  const { id, ...queryParams } = params;

  try {
    const { data } = await axios.get(`${BASE_URL}academies/${id}/dashboard`, {
      params: queryParams,
    });

    return data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error:", error.response?.data || error.message);
      throw new Error(
        error.response?.data?.error || "Failed to fetch dashboard details."
      );
    }
  }
};
