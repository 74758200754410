import { attendanceFormatter } from "Utils/attendanceFormater";
import { Attendance } from "Utils/attendanceFormater";
export const BatchPerformancecolumns = [
  { title: "Name", dataField: "name", colWidth: "15%" },
  { title: "Batch Coach Name", dataField: "batch_coach_name", colWidth: "15%" },
  {
    title: "Attendance (%)",
    dataField: "attendance",
    formatter: (row: { attendance: Attendance }) => {
      return attendanceFormatter(row.attendance, "percent");
    },
    colWidth: "15%",
  },
  {
    title: "Attendance (Count)",
    dataField: "attendance",
    formatter: (row: { attendance: Attendance }) => {
      return attendanceFormatter(row.attendance, "count");
    },
    colWidth: "15%",
  },
  {
    title: "Best Rating(%)",
    dataField: "best_rating_percentage",
    colWidth: "10%",
  },
  {
    title: "Least Rating(%)",
    dataField: "least_rating_percentage",
    colWidth: "10%",
  },
  { title: "Performance", dataField: "performance", colWidth: "10%" },
  { title: "Survey Activity", dataField: "survey_activity", colWidth: "10%" },
];
