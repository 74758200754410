import { useQuery } from "react-query";
import { useMemo } from "react";
import { useAppSelector } from "App/hooks";
import { Accordion, Col, Row } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import SkillsTile from "Components/Shared/SkillsTile";
import { UserSport } from "Components/Shared/UserDetailsForm/constant";
import { PersonalDetailsPropsType } from "Interfaces/Components/Shared/UserDetailsComponentInterface";
import { UserSportObjectType } from "Interfaces/Components/Shared/UserDetailsFormInterface";
import { COACH, PLAYER } from "App/constants";
import { titleCaseConversion } from "Utils/utils";
import CoachLogo from "App/images/coach-icon.svg";
import PlayersLogo from "App/images/players-icon.svg";
import { NO_BATCH_ASSIGNED } from "./constants";
import { fetchPlayerEngagementScore } from "Api/Pages/PlayerEngagementApi";
import { getCurrentMonthDates } from "Utils/utils";

const PersonalDetails: React.FC<PersonalDetailsPropsType> = ({
  playerId,
  skill_coins,
  name,
  contact_number,
  email,
  blood_group,
  dob,
  gender,
  height,
  weight,
  city,
  details,
  coach_sports,
  player_sports,
  country_code,
  academy_batches,
  userType,
  bmi,
  fathers_name,
  mothers_name,
  aadhar_number,
  educational_qualification,
  nationality,
  state,
  address,
  previous_serious_injuries,
  medical_history,
  other_hobbies_and_interest,
  asian_ranking,
  international_ranking,
  national_ranking,
  state_ranking,
}) => {
  const userSports =
    userType && { coach_sports, player_sports }[UserSport[userType]];

  const isPlayer = userType === "Player";
  const { startDate, todayDate } = useMemo(() => getCurrentMonthDates(), []);

  const academy_id = useAppSelector(
    (state) => state.loginReducer.userDetails.academy_id
  );

  interface PlayerEngagementApiResponse {
    app_engagement_score: number;
  }

  const { data, isError, isLoading } = useQuery<PlayerEngagementApiResponse>(
    ["PlayerEngagementScore", academy_id, playerId, startDate, todayDate],
    async () => {
      return fetchPlayerEngagementScore({
        playerId: playerId,
        academyId: academy_id,
        startDate: startDate,
        endDate: todayDate,
      });
    },
    {
      enabled: isPlayer,
    }
  );

  const engagementScore = data?.app_engagement_score ?? 0;
  const normalizedScore = Math.min(Math.max(engagementScore, 1), 100);

  return (
    <>
      <div className="d-grid gap-4 px-4 pb-3">
        <Row>
          <Col lg={4}>
            <div className="user-profile border rounded-circle d-flex align-items-center justify-content-center mb-2">
              <img
                src={userType === COACH ? CoachLogo : PlayersLogo}
                alt="Image1"
              />
            </div>
            {isPlayer && (
              <div>
                {isLoading ? (
                  <p className="text-muted text-center">Loading score...</p>
                ) : isError ? (
                  <p className="text-danger text-center">
                    Unable to fetch score
                  </p>
                ) : (
                  <ProgressBar
                    now={normalizedScore}
                    label={`${normalizedScore}%`}
                    className="w-75"
                  />
                )}

                <h6 className="text-muted fs-xs">Engagement Score</h6>
              </div>
            )}
          </Col>

          {skill_coins >= 0 && [PLAYER, COACH].includes(userType) && (
            <Col lg={4}>
              <SkillsTile skill_coins={skill_coins} />
            </Col>
          )}
        </Row>
        <Row>
          <Col lg={4}>
            <label className="fs-xs">{`${userType} Name`}</label>
            <p className="fs-sm fw-bold mb-0">{name}</p>
          </Col>
          <Col lg={4}>
            <label className="fs-xs"> Contact Number</label>
            <p className="fs-sm fw-bold mb-0">
              {country_code
                ? `${country_code} ${contact_number || "-"}`
                : contact_number || "-"}
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <label className="fs-xs">Email ID</label>
            <p className="fs-sm fw-bold mb-0">{email || "-"}</p>
          </Col>
          <Col lg={8}>
            <label className="fs-xs">{`More Details About ${userType}`}</label>
            <p className="fs-sm fw-bold mb-0">{details || "-"}</p>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <label className="fs-xs">Date of Birth</label>
            <p className="fs-sm fw-bold mb-0">{dob || "-"}</p>
          </Col>
          <Col lg={4}>
            <label className="fs-xs">City</label>
            <p className="fs-sm fw-bold mb-0">{city || "-"}</p>
          </Col>
        </Row>
        {isPlayer && (
          <Row>
            <Col lg={4}>
              <label className="fs-xs">Blood Group</label>
              <p className="fs-sm fw-bold mb-0">{blood_group || "-"}</p>
            </Col>
            <Col lg={4}>
              <label className="fs-xs">Gender</label>
              <p className="fs-sm fw-bold mb-0">{gender || "-"}</p>
            </Col>
          </Row>
        )}
        <Row>
          <Col lg={4}>
            <label className="fs-xs">Height (cm)</label>
            <p className="fs-sm fw-bold mb-0">{height || "-"}</p>
          </Col>
          <Col lg={4}>
            <label className="fs-xs">Weight (kg)</label>
            <p className="fs-sm fw-bold mb-0">{weight || "-"}</p>
          </Col>
        </Row>
        {isPlayer && (
          <Row>
            <Col lg={4}>
              <label className="fs-xs">BMI</label>
              <p className="fs-sm fw-bold mb-0">{bmi || "-"}</p>
            </Col>
          </Row>
        )}
      </div>

      {isPlayer && (
        <div className="py-3">
          <Accordion>
            <AccordionItem eventKey="0">
              <AccordionHeader>
                <span className="fw-bold">Demographic details</span>
              </AccordionHeader>
              <AccordionBody>
                <div className="d-grid gap-4 pb-3">
                  <Row>
                    <Col lg={4}>
                      <label className="fs-xs">Fathers Name</label>
                      <p className="fs-sm fw-bold mb-0">
                        {fathers_name || "-"}
                      </p>
                    </Col>
                    <Col lg={4}>
                      <label className="fs-xs">Mothers Name</label>
                      <p className="fs-sm fw-bold mb-0">
                        {mothers_name || "-"}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <label className="fs-xs">Aadhar number</label>
                      <p className="fs-sm fw-bold mb-0">
                        {aadhar_number || "-"}
                      </p>
                    </Col>
                    <Col lg={4}>
                      <label className="fs-xs">Educational Qualification</label>
                      <p className="fs-sm fw-bold mb-0">
                        {educational_qualification || "-"}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <label className="fs-xs">Nationality</label>
                      <p className="fs-sm fw-bold mb-0">{nationality || "-"}</p>
                    </Col>
                    <Col lg={4}>
                      <label className="fs-xs">State</label>
                      <p className="fs-sm fw-bold mb-0">{state || "-"}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={8}>
                      <label className="fs-xs">Address</label>
                      <p className="fs-sm fw-bold mb-0">{address || "-"}</p>
                    </Col>
                  </Row>
                </div>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem eventKey="1">
              <AccordionHeader>
                <span className="fw-bold">Additional details</span>
              </AccordionHeader>
              <AccordionBody>
                <div className="d-grid gap-4 pb-3">
                  <Row>
                    <Col lg={4}>
                      <label className="fs-xs">Previous Serious Injuries</label>
                      <p className="fs-sm fw-bold mb-0">
                        {previous_serious_injuries || "-"}
                      </p>
                    </Col>
                    <Col lg={4}>
                      <label className="fs-xs">Medical History</label>
                      <p className="fs-sm fw-bold mb-0">
                        {medical_history || "-"}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <label className="fs-xs">
                        Other Hobbies and Interests
                      </label>
                      <p className="fs-sm fw-bold mb-0">
                        {other_hobbies_and_interest || "-"}
                      </p>
                    </Col>
                  </Row>
                </div>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem eventKey="2">
              <AccordionHeader>
                <span className="fw-bold">Sports performance profile</span>
              </AccordionHeader>
              <AccordionBody>
                <div className="d-grid gap-4 pb-3">
                  <Row>
                    <Col lg={4}>
                      <label className="fs-xs">International Ranking</label>
                      <p className="fs-sm fw-bold mb-0">
                        {international_ranking || "-"}
                      </p>
                    </Col>
                    <Col lg={4}>
                      <label className="fs-xs">Asian Ranking</label>
                      <p className="fs-sm fw-bold mb-0">
                        {asian_ranking || "-"}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <label className="fs-xs">National Ranking</label>
                      <p className="fs-sm fw-bold mb-0">
                        {national_ranking || "-"}
                      </p>
                    </Col>
                    <Col lg={4}>
                      <label className="fs-xs">State Ranking</label>
                      <p className="fs-sm fw-bold mb-0">
                        {state_ranking || "-"}
                      </p>
                    </Col>
                  </Row>
                </div>
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>
      )}

      {userSports && userSports.length ? (
        <div className="sports-details-wrap p-4 d-grid gap-4">
          {userSports.map((sportItem: UserSportObjectType, index: number) => (
            <div key={`sport_${index + 1}`} className="d-grid gap-2">
              <Row>
                <Col lg={4}>
                  <label className="fs-xs">Sport</label>
                  <p className="fs-sm fw-bold mb-0">{sportItem?.sport?.name}</p>
                </Col>
                {userType === PLAYER ? (
                  <Col lg={4}>
                    <label className="fs-xs">Level</label>
                    <p className="fs-sm fw-bold mb-0">
                      {titleCaseConversion(sportItem.level)}
                    </p>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
              <Row>
                <Col lg={4}>
                  <label className="fs-xs">Rank</label>
                  <p className="fs-sm fw-bold mb-0">
                    {sportItem.rank + sportItem.sub_rank}
                  </p>
                </Col>
                <Col lg={4}>
                  <label className="fs-xs">Matches played</label>
                  <p className="fs-sm fw-bold mb-0">
                    {sportItem.matches_played}
                  </p>
                </Col>
              </Row>
            </div>
          ))}
          <div className="d-grid gap-2">
            <p className="fs-6 fw-bold mb-0">Batches</p>
            {academy_batches?.length ? (
              academy_batches?.map((academyDetail: any, index: number) => (
                <p
                  className="fs-sm fw-bold mb-0 user-text"
                  key={academyDetail.name + index}
                >
                  {academyDetail?.name}
                </p>
              ))
            ) : (
              <p className="fs-sm fw-bold mb-0 user-text">
                {NO_BATCH_ASSIGNED}
              </p>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default PersonalDetails;
