export interface Attendance {
  attended_days_count: number;
  common_days_count: number;
}

export function attendanceFormatter(
  attendance: Attendance = {
    attended_days_count: 0,
    common_days_count: 0,
  },
  type: "count" | "percent"
) {
  const attended = attendance.attended_days_count;
  const total = attendance.common_days_count;

  if (total === 0) return type === "count" ? "[0/0]" : "0.00%";

  if (type === "count") {
    return `[${attended}/${total}]`;
  }

  return `${((attended / total) * 100).toFixed(2)}%`;
}
