import React from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { VideoCardProps } from "Interfaces/Components/Shared/DashboardInterface";
import defaultImage from "App/images/default-video-icon.png";
import "./videoCard.scss";
const VideoCard: React.FC<VideoCardProps> = ({
  assetStartDate,
  assetEndDate,
  mostRatedDrill,
  mostRatedFeatured,
  hightestViewedDrill,
  hightestViewedFeatured,
}) => {
  const navigate = useNavigate();

  const videos = [
    {
      label: "Highest Rated Internal Video",
      data: mostRatedFeatured,
      route: "featured-videos",
      metricLabel: "Total Rating",
      metric: mostRatedFeatured?.total_points,
    },
    {
      label: " Highest Viewed Internal Video",
      data: hightestViewedFeatured,
      route: "featured-videos",
      metricLabel: "Total Views",
      metric: hightestViewedFeatured?.total_views,
    },
    {
      label: "Highest Rated Global Video",
      data: mostRatedDrill,
      route: "drills/details",
      metricLabel: "Total Rating",
      metric: mostRatedDrill?.total_points,
    },
    {
      label: "Highest Viewed Global Video",
      data: hightestViewedDrill,
      route: "drills/details",
      metricLabel: "Total Views",
      metric: hightestViewedDrill?.total_views,
    },
  ];

  return (
    <div className="card top-videos overflow-auto">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="card-title">Top Videos</h5>
          <span className="date-range text-muted">{`${assetStartDate}-${assetEndDate}`}</span>
        </div>

        <div className="row">
          {videos.map((video, index) => (
            <div key={index} className="col-lg-3 col-md-6 mb-4">
              {video.data ? (
                <Card
                  className="video-card"
                  onClick={() => navigate(`/${video.route}/${video.data.id}`)}
                >
                  <img
                    src={video.data.thumbnail_url || defaultImage}
                    className="card-img-top"
                    alt={video.data.title || "No video available"}
                  />
                  <Card.Body>
                    <Card.Title>
                      {video.data.title || "No Title Available"}
                    </Card.Title>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>{video.label}</span>
                      <FontAwesomeIcon
                        icon={faTrophy}
                        className="activity-icon text-warning me-3"
                      />
                    </div>
                    <p className="text-muted mt-2">
                      {video.metricLabel}:{" "}
                      <strong>{video.metric ?? "N/A"}</strong>
                    </p>
                  </Card.Body>
                </Card>
              ) : (
                <Card className="video-card">
                  <div className="d-flex justify-content-center align-items-center text-muted">
                    <p className="text-center">No {video.label} available</p>
                  </div>
                </Card>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
