import React, { useCallback, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, ListGroup } from "Components/Core";
import {
  ButtonIcon,
  SearchControl,
  DrillsListComponent,
} from "Components/Shared";
import { getMasterList } from "Api/Pages/BatchesApi";
import {
  AssignComponentMasterList,
  AssignComponentProps,
} from "Interfaces/Pages/BatchesInterface";
import { BatchSessionMap, GlobalItemMap } from "Pages/Batches/constants";
import {
  COACH,
  DRILLS,
  RECORDS_PER_PAGE,
  SURVEY_QUESTION,
} from "App/constants";
import { useAppSelector, useScroller } from "App/hooks";

const AssignComponent = ({
  userType,
  assignButtonClicked,
  setAssignButtonClicked,
  selectedList,
  assignBatchItem,
  applicableFor,
  selectedSession,
  isReplace,
  setIsReplace,
  batchSport,
  batchId,
}: AssignComponentProps) => {
  const pageEnd = useRef<HTMLDivElement>(null),
    [loading, setLoading] = useState<boolean>(false),
    [searchInput, setSearchInput] = useState<string>(""),
    [filteredList, setFilteredList] = useState<AssignComponentMasterList[]>([]),
    [selectedUserIds, setSelectedUserIds] = useState<number[]>([]),
    {
      userDetails: { academy_id },
    } = useAppSelector((state) => state.loginReducer);

  const fetchMasterUserList = useCallback(
    async (page: number) => {
      let list = await getMasterList({
        academyId: academy_id,
        user: userType,
        batchId: batchId,
        page: page,
        searchInput: searchInput,
        applicable_for: applicableFor,
        trigger_event: BatchSessionMap[selectedSession],
        sportId: [DRILLS, SURVEY_QUESTION].includes(userType)
          ? batchSport.master_sport_id
          : batchSport.id,
      });
      const { total_count } = list;

      list = list[GlobalItemMap[userType]];
      if (searchInput !== "") {
        setFilteredList([...list]);
      } else {
        if (loading) {
          setFilteredList((prev) => {
            const newList = list.filter(
              (item: any) => !prev.some((prevItem) => prevItem.id === item.id)
            );
            return [...prev, ...newList];
          });
        } else setFilteredList([...list]);
      }
      total_count > RECORDS_PER_PAGE && setLoading(true);
    },
    [
      academy_id,
      userType,
      batchId,
      searchInput,
      applicableFor,
      selectedSession,
      batchSport,
      loading,
    ]
  );

  const { pageNumber } = useScroller({
    pageEnd,
    loading,
    searchInput,
  });

  const handleSearch = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = event;
      setSearchInput(value);
    },
    []
  );

  const toggleAssignSideBar = () => {
    setAssignButtonClicked(false);
    setIsReplace(false);
    setSearchInput("");
    setSelectedUserIds([]);
  };

  const handleAssign = () => {
    selectedUserIds.length && assignBatchItem(selectedUserIds);
    setSelectedUserIds([]);
    setAssignButtonClicked(false);
    setIsReplace(false);
  };

  const handleClick = (user: AssignComponentMasterList) => {
    if (selectedUserIds.length && selectedUserIds.includes(user.id)) {
      setSelectedUserIds(() => selectedUserIds.filter((id) => id !== user.id));
    } else if (isReplace || userType === COACH) {
      setSelectedUserIds([user.id]);
    } else {
      setSelectedUserIds([...selectedUserIds, user.id]);
    }
  };
  const isAssignDisabled = () => {
    if (
      userType === COACH &&
      !isReplace &&
      selectedUserIds.length + selectedList.length > 1
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    fetchMasterUserList(pageNumber);
  }, [pageNumber, fetchMasterUserList]);

  const printList = () => {
    return (
      <>
        <ListGroup className="ss-list-group gap-2">
          {filteredList &&
            filteredList.map(
              (user: AssignComponentMasterList, index: number) => (
                <ListGroup.Item
                  className="p-3 fs-sm text-capitalize"
                  key={`filtered_${user?.id}_${index}`}
                  active={
                    selectedUserIds.length && selectedUserIds.includes(user.id)
                      ? true
                      : false
                  }
                  onClick={() => handleClick(user)}
                >
                  {userType === SURVEY_QUESTION ? user?.title : user?.name}
                </ListGroup.Item>
              )
            )}
          <div className="d-flex justify-content-center mt-4 fw-bold">
            {!filteredList?.length && "No Data Found"}
          </div>
        </ListGroup>
      </>
    );
  };

  return (
    <>
      <Modal
        className="ss-modal right_modal"
        show={assignButtonClicked}
        onHide={toggleAssignSideBar}
      >
        <Modal.Header className="fw-bold fs-sm border-0">
          {isReplace ? `Replace ${userType}` : `Assign ${userType}`}
          <ButtonIcon className="fs-5" onClick={toggleAssignSideBar}>
            <FontAwesomeIcon icon={faTimes} />
          </ButtonIcon>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column scroll-y p-0">
          <div className="d-flex justify-content-between gap-2 p-3">
            <SearchControl
              placeholder="Search"
              label="Search"
              className={
                userType === SURVEY_QUESTION ? "flex-1 survey-search" : "w-75"
              }
              onChange={handleSearch}
            />
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={handleAssign}
              disabled={isAssignDisabled()}
            >
              {isReplace ? `Replace ${userType}` : `Assign ${userType}`}
            </Button>
          </div>
          <div className="d-flex flex-column scroll-y flex-1 px-3 pb-3 gap-2">
            {userType === DRILLS ? (
              <DrillsListComponent
                drillsList={filteredList}
                onDrillClick={handleClick}
                isAssignList={true}
                selectedUserIds={selectedUserIds}
              />
            ) : (
              printList()
            )}
            <div ref={pageEnd}></div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AssignComponent;
