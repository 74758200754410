import axios from "axios";
import {
  AddPlayerParam,
  DeleteCoachParam,
  GetCoachListParam,
} from "Interfaces/AppInterface";
import { ACADEMY_BASE_URL } from "App/constants";
import { USER_ATTRIBUTES } from "Pages/Players/constants";
import { getAcademyId } from "Utils/utils";

export const deleteCoach = async ({ academyId, id }: DeleteCoachParam) => {
  const { data } = await axios.put(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/coaches/${id}/deactivate`,
    {
      coach: {
        is_active: "false",
      },
    }
  );

  return data;
};

export const getCoachList = async ({
  academyId,
  searchInput,
  selectValue,
  currentPage,
}: GetCoachListParam) => {
  const queryParams = `page=${currentPage}&sport_id=${selectValue}&search=${searchInput}&order_by=name&order_type=asc`;
  const { data } = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/coaches?${queryParams}`
  );
  if (data) {
    return data;
  }
  return [];
};

export const addCoach = async ({
  academyId,
  param,
  isEditUser,
}: AddPlayerParam) => {
  const user_attributes: any = {};
  USER_ATTRIBUTES.forEach((ele: any) => {
    user_attributes[ele] = param[ele];
    delete param[ele];
  });
  delete param.image;
  if (isEditUser) {
    param.coach_sports &&
      param.coach_sports.length &&
      param.coach_sports.forEach((sportData: any) => {
        delete sportData.sport;
      });
    await axios.put(
      `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/coaches/${param.id}`,
      {
        coach: {
          ...param,
          user_attributes,
        },
      }
    );
    return;
  }
  await axios.post(`${ACADEMY_BASE_URL}${getAcademyId(academyId)}/coaches`, {
    coach: {
      ...param,
      user_attributes,
    },
  });

  return [];
};

export const getAllCoach = async (sportId: string, searchInput: string) => {
  const data = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId()}/coaches?sport_id=${sportId}&search=${searchInput}`
  );
  if (data) {
    return data.data.coaches;
  }
  return [];
};
