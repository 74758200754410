import { useCallback, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav, Button } from "Components/Core";
import { ButtonIcon, Icon } from "Components/Shared";
import { logout } from "Api/LogoutApi";
import { ASIDE_LINKS, PROFILE_LINKS, Enable_Nav } from "./constants";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { useAppSelector, useAppDispatch } from "App/hooks";
import { setNotificationCount } from "Pages/Notifications/notificationReducer";
import { fetchNotificationCount } from "Api/Pages/NotificationsApi";
import { isAllowedTo } from "Utils/helper";
import logo from "App/images/site-logo@2x.png";
import "./aside.scss";

const Aside = () => {
  const notificationCount = useAppSelector(
    (state) => state.notificationReducer.count
  );

  const dispatch = useAppDispatch();
  const location = useLocation(),
    navigate = useNavigate(),
    {
      userDetails: { permissions, academy_id, academy_name },
    } = useAppSelector((state) => state.loginReducer),
    isActivePage = (link: string) => location.pathname.includes(link),
    logOutUser = useCallback(async () => {
      await logout();
      localStorage.clear();
      navigate("/");
    }, [navigate]);

  const getAdminNotificationCount = useCallback(async () => {
    const count = await fetchNotificationCount(academy_id);
    dispatch(setNotificationCount(count));
  }, [academy_id, dispatch]);

  const displayNavItem = () => {
    return ASIDE_LINKS.map((asideLink, i) => {
      const { resourceType, link, text, icon, action } = asideLink;
      if (isAllowedTo(resourceType, action || "index", permissions))
        return (
          <Link
            // added temporary just for disabling the things which are not developed
            style={{
              pointerEvents: Enable_Nav.includes(text) ? "auto" : "none",
            }}
            to={link ? link : "/"}
            onClick={getAdminNotificationCount}
            key={text}
            className={`aside-nav-link nav-link d-flex align-items-center gap-3 text-white text-decoration-none ${
              isActivePage(link) ? "active" : ""
            }`}
          >
            <Icon name={icon} className={`${icon}`} />
            <span className="aside-nav-link-text fs-sm">{text}</span>
          </Link>
        );
      return null;
    });
  };

  useEffect(() => {
    getAdminNotificationCount();
  }, [getAdminNotificationCount]);

  const profileLinks = PROFILE_LINKS.map((profileLink, i) => {
    if (profileLink.icon.iconName === faBell.iconName) {
      return (
        <Link
          to="/notifications"
          key="Notifications"
          className={isActivePage("/notifications") ? "active" : ""}
        >
          <ButtonIcon className="button-icon" key={`icon_${i}`}>
            <FontAwesomeIcon icon={profileLink.icon} />
            <div
              style={{
                position: "relative",
                right: "1px",
                top: "-16px",
                color: "red",
              }}
            >
              <span style={{ position: "absolute" }}>{notificationCount}</span>
            </div>
          </ButtonIcon>
        </Link>
      );
    }

    return (
      <ButtonIcon className="button-icon" key={`icon_${i}`}>
        <FontAwesomeIcon icon={profileLink.icon} />
      </ButtonIcon>
    );
  });

  return (
    <aside className="ss-page-aside d-flex flex-column scroll-y">
      <div className="ss-aside-head">
        <div className="ss-logo-box mx-auto mb-3 d-flex align-items-center justify-content-center">
          <img src={logo} alt="site logo" />
        </div>
        {academy_id && (
          <span className="fs-medium text-white text-center d-block fw-bold">
            {academy_name}
          </span>
        )}
        <div className="ss-profile-details d-flex justify-content-center gap-4">
          {profileLinks}
        </div>
      </div>
      <Nav className="aside-nav-link-list flex-column flex-1 scroll-y flex-nowrap">
        {displayNavItem()}
        <div className="px-3 pt-3">
          <Button
            variant="outline-light"
            onClick={logOutUser}
            className="fs-mini px-4"
          >
            Logout
          </Button>
        </div>
      </Nav>
      <div className="ss-aside-footer d-flex flex-column gap-3 px-3 py-2">
        <div className="d-flex">
          <span className="fs-mini text-white">Powered by</span>
          <img src={logo} alt="site logo" className="ms-3" />
        </div>
      </div>
    </aside>
  );
};

export default Aside;
