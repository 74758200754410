import {
  MatchPlayDataType,
  MatchPLayWinningStatus,
} from "Interfaces/Pages/MatchPlayHistoryInterface";

export const matchPlayColumns = [
  {
    title: "Organizer",
    formatter: (props: MatchPlayDataType) => {
      if (props && props.organizer_name) {
        const is_user_winner = props.is_user_winner || "N/A"; // Default to "N/A" if undefined

        return (
          <div
            className={`border-start border-4 ${MATCH_PLAY_STATUS_CSS[is_user_winner]}`}
          >
            <span className="ms-2">{props.organizer_name}</span>
          </div>
        );
      }
    },
    colWidth: "20%",
  },

  { title: "Sport", dataField: "master_sport_name", colWidth: "10%" },
  { title: "Type", dataField: "of_type", colWidth: "10%" },
  { title: "Scheduled At", dataField: "scheduled_at", colWidth: "10%" },
  { title: "Participants", dataField: "participant_names", colWidth: "30%" },
  {
    title: "Format",
    formatter: (props: MatchPlayDataType) => {
      if (props?.engagement_formats) {
        const format = props.engagement_formats.join(", ");
        return format;
      }
      return "-";
    },
    colWidth: "20%",
  },
];

export const MATCH_PLAY_STATUS_CSS: MatchPLayWinningStatus = {
  Yes: "border-success",
  No: "border-danger",
  "N/A": "border-dark",
};
