import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Row, Col, Card, Spinner, Alert, ProgressBar } from "react-bootstrap";

import CommentBox from "../Comments";
import { fetchComments } from "Api/Pages/ScheduleApi";
import {
  ScheduleDataType,
  CommentApiResponse,
} from "Interfaces/Components/Shared/ScheduleInterface";

import "./ScheduleDetails.css";

const ScheduleDetails = () => {
  const location = useLocation();

  const schedule = location.state as ScheduleDataType;

  const { id } = useParams<{ id: string }>();

  const {
    data: comments,
    isLoading,
    error,
  } = useQuery<CommentApiResponse>(
    ["comments", id],
    () => {
      return fetchComments(Number(id));
    },
    {
      enabled: !!schedule.id, // Only run the query if schedule ID is available
    }
  );

  if (isLoading) {
    return (
      <div>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading comments...</span>
        </Spinner>
      </div>
    );
  }

  // Handle error state
  if (error) {
    return <Alert variant="danger">Error loading comments.</Alert>;
  }

  if (!schedule) {
    return <div>No details available.</div>;
  }

  return (
    <div className="schedule-details-container p-4 bg-light rounded">
      <Card className="shadow-sm rounded">
        <Card.Body>
          <Card.Title className="text-primary fs-5">
            {schedule.title}
          </Card.Title>
          <Card.Text className="text-muted fs-6">
            {schedule.description || "N/A"}
          </Card.Text>

          <Row className="mb-3 ">
            <Col lg={6}>
              <strong>Start Date:</strong> {schedule.start_date}
            </Col>
            <Col lg={6}>
              <strong>End Date:</strong> {schedule.end_date}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col lg={6}>
              <strong>Type:</strong> {schedule.of_type}
            </Col>
            <Col lg={6}>
              <strong>Sport:</strong> {schedule.sport_name}
            </Col>
          </Row>

          {["Activity", "Injury"].includes(schedule.of_type) && (
            <Row className="mb-3">
              <Col>
                <strong>Intensity:</strong>
                <ProgressBar
                  now={schedule.rating_points ? schedule.rating_points * 10 : 0} // Assuming intensity is between 0 to 10
                  label={`${schedule.rating_points || 0}/10`}
                  className="mt-2"
                />
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>

      {/* Comments Section */}
      <Col lg={6}>
        <h3 className="mt-4 fw-bold mb- fs-6">Comments</h3>
        {comments?.data && comments.data.length > 0 ? (
          comments.data.map((comment) => (
            <CommentBox
              key={comment.id}
              id={comment.id}
              commented_by={comment.commented_by}
              created_at={comment.created_at}
              text={comment.text}
            />
          ))
        ) : (
          <Alert variant="info">No comments available.</Alert>
        )}
      </Col>
    </div>
  );
};

export default ScheduleDetails;
