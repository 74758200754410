import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
  faUser,
  faPlane,
  faUserInjured,
} from "@fortawesome/free-solid-svg-icons";
import { PlayerScheduleProp } from "Interfaces/Components/Shared/DashboardInterface";

const PlayerActivity: React.FC<{
  playerSchedule: PlayerScheduleProp;
  todayDate: string;
}> = ({ playerSchedule, todayDate }) => {
  const navigate = useNavigate();

  const handleRowClick = (type: string) => {
    navigate(
      `/user_schedules?of_type=${type}&start_date=${todayDate}&end_date=${todayDate}&entity_type_for_academy_schedules=Player`
    );
  };

  const activities = [
    {
      type: "leave",
      label: "Players on Leave",
      count: playerSchedule.Leave,
      icon: faUser,
      badgeColor: "bg-primary",
      iconColor: "text-primary",
    },
    {
      type: "travel",
      label: "Players Travelling",
      count: playerSchedule.Travel,
      icon: faPlane,
      badgeColor: "bg-warning",
      iconColor: "text-warning",
    },
    {
      type: "injury",
      label: "Players Injured",
      count: playerSchedule.Injury,
      icon: faUserInjured,
      badgeColor: "bg-danger",
      iconColor: "text-danger",
    },
  ];

  const activityItems = activities.map((activity, index) => (
    <div
      key={index}
      className="activity-item d-flex align-items-center my-3"
      onClick={() => handleRowClick(activity.type)}
      // onMouseEnter={handleMouseEnter}
      // onMouseLeave={handleMouseLeave}
      // style={{ cursor: "pointer" }}
    >
      <FontAwesomeIcon
        icon={activity.icon}
        className={`activity-icon ${activity.iconColor} me-3`}
        size="2x"
      />
      <span className="activity-label flex-grow-1">{activity.label}</span>
      <span className={`activity-count badge ${activity.badgeColor} fs-6`}>
        {activity.count}
      </span>
    </div>
  ));

  return (
    <div className="card top-players overflow-auto">
      <div className="card-body">
        {/* Today's Date */}
        <span
          className="text-muted position-absolute top-0 end-0 m-3"
          style={{ fontSize: "0.9rem" }}
        >
          {`${todayDate}`}
        </span>
        <h5 className="card-title">Players Activities</h5>
        <div className="activity-list">{activityItems}</div>
      </div>
    </div>
  );
};

export default PlayerActivity;
