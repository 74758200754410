import React from "react";
import "./card.scss";
import { TileItems } from "./constants";
import { useNavigate } from "react-router-dom";
import { CardProps } from "Interfaces/Components/Shared/DashboardInterface";

const Card: React.FC<CardProps> = ({ totalCoaches, totalPlayers }) => {
  const navigate = useNavigate();

  const handleCardClick = (type: string) => {
    navigate(`/${type}`);
  };

  const cardItems = TileItems.map((card, index) => (
    <div
      key={index}
      className="card-item col-lg-6 col-md-12"
      onClick={() => handleCardClick(card.type)}
    >
      <div className="card info-card sales-card">
        <div className="card-body">
          <div className="d-flex align-items-center w-100 pt-4">
            <div className="me-3">
              <h6 className="fw-bold fs-1 ms-5">
                {card.type === "players" ? totalPlayers : totalCoaches}
              </h6>
            </div>

            <div className="d-flex flex-column align-items-start ms-5">
              <h5 className="card-title">{card.title}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));

  return <div className="row">{cardItems}</div>;
};

export default Card;
