export const sportsIcons = {
  player: "icon-player",
  coach: "icon-coach",
};

export const TileItems = [
  {
    type: "players",
    title: "Total Players",
    // count: 100,
    icon: sportsIcons.player,
  },
  // {
  //   title: "Active Players",
  //   count: 76,
  //   icon: sportsIcons.player,
  // },

  {
    type: "coaches",
    title: "Total Coaches",
    // count: 20,
    icon: sportsIcons.coach,
  },

  // {
  //   title: "Active Coaches",
  //   count: 34,
  //   icon: sportsIcons.coach,
  // },
];

export const topPlayerHeaders = [
  "ID",
  "Player Name",
  "Skill Coins",
  "Total Sports",
  "Skill Points",
];
