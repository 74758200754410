import React from "react";
import "./topPlayer.scss";
import { useNavigate } from "react-router-dom";
import { TopPlayersProps } from "Interfaces/Components/Shared/DashboardInterface";
import { topPlayerHeaders } from "./constants";

const TopPlayers: React.FC<TopPlayersProps> = ({
  topPlayers,
  topPlayerStartDate,
  topPlayerEndDate,
}) => {
  const navigate = useNavigate();

  // Handler for row click
  const handleRowClick = (playerId: number) => {
    navigate(`/players/${playerId}`);
  };

  const tableHeaders = topPlayerHeaders.map((header, index) => (
    <th key={index} scope="col">
      {header}
    </th>
  ));

  const playerRows =
    topPlayers.length > 0 ? (
      topPlayers.map((player, index) => (
        <tr
          key={player.id}
          onClick={() => handleRowClick(player.id)}
          className="player-row"
        >
          <th scope="row">{index + 1}</th>
          <td>{player.name}</td>
          <td>{player.skill_coins}</td>
          <td>{player.sport_count}</td>
          <td>{player.total_periodic_skill_coins}</td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={topPlayerHeaders.length} className="text-center">
          No Data Available
        </td>
      </tr>
    );

  return (
    <div className="card top-players overflow-auto">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="card-title">{`TOP ${topPlayers.length} Players`}</h5>
          <span className="date-range text-muted">{`${topPlayerStartDate} - ${topPlayerEndDate}`}</span>
        </div>

        <table className="table table-borderless dataTable">
          <thead className="table-light">
            <tr>{tableHeaders}</tr>
          </thead>
          <tbody>{playerRows}</tbody>
        </table>
      </div>
    </div>
  );
};

export default TopPlayers;
