export const columns = [
  { dataField: "participant_name", title: "Name", colWidth: "20%" },
  { dataField: "title", title: "Title", colWidth: "10%" },
  { dataField: "description", title: "Description", colWidth: "30%" },
  {
    dataField: "start_date",
    title: "Start Date",
    colWidth: "10%",
    formatter: (data: any) => new Date(data.start_date).toLocaleDateString(),
  },
  {
    dataField: "end_date",
    title: "End Date",
    colWidth: "10%",
    formatter: (data: any) => new Date(data.end_date).toLocaleDateString(),
  },
  { dataField: "rating_points", title: "Rating Points", colWidth: "10%" },
  { dataField: "sport_name", title: "Sport", colWidth: "10%" },
];
